/* General article styling */
article {
    font-family: 'Arial', sans-serif;
    line-height: 1.5; /* Reduced line spacing */
    color: #333;
    background: #fff;
    padding: 15px; /* Slightly reduced padding */
    border-radius: 8px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Headings */
  h4 {
    font-size: 1.6rem; /* Slightly reduced */
    color: #ee3a38;
    margin-bottom: 8px; /* Reduced spacing */
    font-weight: 700;
    border-left: 4px solid #ee3a38;
    padding-left: 8px;
  }
  
  /* Paragraph styling */
  p, span {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 10px; /* Reduced space below paragraphs */
    padding: 8px;
  }
  
  /* Links */
  a {
    font-size: 1.1rem;
    color: #2620e6;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
  }
  
  a:hover {
    color: #4636c1;
    text-decoration: underline;
  }
  
  /* Ordered and Unordered Lists */
  ol, ul {
    padding-left: 18px; /* Reduced left padding */
    margin-bottom: 10px; /* Less space between lists */
    color: #444;
  }
  
  ol li, ul li {
    font-size: 1.1rem;
    margin-bottom: 4px; /* Less space between list items */
    padding-left: 4px;
    color: #444;
  }
  
  /* Bootstrap-specific List Styling */
  .list-group-item {
    font-size: 1.1rem;
    border: none;
    padding: 8px 12px; /* Reduced padding */
    background: #f8f9fa;
    margin-bottom: 4px; /* Less space between items */
    border-radius: 4px;
    color: #444;
  }
  
  .list-group-item:hover {
    background: #e9ecef;
    transition: 0.3s ease-in-out;
    color: #444;
  }

  strong{
    font-size: 1.1rem; /* Slightly reduced */
    color: #ee3a38;
    margin-bottom: 8px; /* Reduced spacing */
    font-weight: 700;
    /* border-left: 4px solid #ee3a38; */
    padding-left: 8px;
  }

  ::selection {
    background-color: #ee3a38;
    color: white; /* Optional: Change text color for better visibility */
  }

  
  
  